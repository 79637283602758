import React from "react"
import styled from "styled-components"
import { LinkWrapper as Link } from "../../../../utils/linkWrapper"
import SvgIcon from "../../../atoms/icons/svg-icon"
import Calendar from "../../../atoms/icons/files/calendar.jsx"
import Tag from "../../../atoms/icons/files/tag.jsx"

const RecentArticles = ({ articles = [] }) => {
  const title = "Most Recent Articles"
  const iconHue = "ocean"
  const iconShade = "080"
  return (
    <Container>
      <Title>
        <SvgIcon SvgComponent={Calendar} hue={iconHue} shade={iconShade} />
        <h5>{title}</h5>
      </Title>
      <OptionContainer>
        {articles.map(({ title, slug, categories }, i) => (
          <Option
            key={slug + i}
            title={title}
            slug={slug}
            categories={categories.nodes}
          />
        ))}
      </OptionContainer>
    </Container>
  )
}

const Container = styled.div``
const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .icon {
    margin-right: 10px;
  }
  h5 {
    font-size: 18px;
    font-weight: bold;
  }
`
const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
`

// =========================
//        OPTION
// =========================

const Option = ({ title = "", slug = "", categories = [] }) => {
  return (
    <OptionWrap to={"/sleep-matters/" + slug}>
      <p dangerouslySetInnerHTML={{ __html: title }} />
      <CategoryGroup>
        <SvgIcon SvgComponent={Tag} hue="ocean" shade="080" />
        {categories.map(({ name }, i) => (
          <Label key={name + i} dangerouslySetInnerHTML={{ __html: name }} />
        ))}
      </CategoryGroup>
    </OptionWrap>
  )
}

const OptionWrap = styled(Link)`
  color: white;
  padding: 15px;
  padding-right: 15px;
  background: #223247;
  border-left: 0px solid #0988bb;
  &:not(:last-child) {
    margin-bottom: 2px;
  }

  > p {
    font-size: 16px;
    padding-bottom: 10px;
  }

  transition: 0.2s all ease-in-out;
  &:hover {
    padding-right: 10px;
    border-left: 5px solid #0988bb;
  }
`

const CategoryGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`
const Label = styled.div`
  margin: 5px;
  font-size: 11px;
  color: white;
  padding: 3px 7px;
  border-radius: 3px;
  background: #0988bb;
`

export default RecentArticles
