import React from "react"

const SocialEmail = ({ fill }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.209,6H7.245a4.24,4.24,0,0,0-4.224,4.245L3,35.718a4.258,4.258,0,0,0,4.245,4.245H41.209a4.258,4.258,0,0,0,4.245-4.245V10.245A4.258,4.258,0,0,0,41.209,6Zm0,8.491L24.227,25.1,7.245,14.491V10.245L24.227,20.859,41.209,10.245Z"
      fill={fill}
    />
  </svg>
)

export default SocialEmail
