import React from "react"
import RecentArticles from "./RecentArticles"
import { graphql, useStaticQuery } from "gatsby"

const RecentArticlesContainer = ({ currentSlug }) => {
  const {
    allLocalWpGraphQlPosts: { nodes },
  } = useStaticQuery(POSTS_QUERY)

  // eslint-disable-next-line react/display-name
  const articles = React.useMemo(
    () => nodes.filter(({ slug }) => slug !== currentSlug),
    [nodes, currentSlug]
  )

  return <RecentArticles articles={articles} />
}

export default RecentArticlesContainer

const POSTS_QUERY = graphql`
  {
    allLocalWpGraphQlPosts(limit: 10, sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug
        categories {
          nodes {
            name
          }
        }
      }
    }
  }
`
