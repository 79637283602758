import React, { useRef } from "react"
import styled from "styled-components"
import SvgIcon from "../../../atoms/icons/svg-icon"
import Facebook from "../../../atoms/icons/files/social-facebook"
import Twitter from "../../../atoms/icons/files/social-twitter"
import LinkedIn from "../../../atoms/icons/files/social-linkedin"
import MailIcon from "../../../atoms/icons/files/social-email"
import WhatsAppIcon from "../../../atoms/icons/files/social-whatsapp"
import LinkIcon from "../../../atoms/icons/files/social-link"
import useSiteMetaData from "../../../../hooks/use-site-meta-data"

const buttons = [
  {
    icon: Twitter,
    label: "Twitter",
    generateLink: (url) => `https://twitter.com/intent/tweet?text=${url}`,
  },
  {
    icon: Facebook,
    label: "Facebook",
    generateLink: (url) =>
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
  },
  {
    icon: LinkedIn,
    label: "LinkedIn",
    generateLink: (url) =>
      `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
  },
  {
    icon: MailIcon,
    label: "E-mail",
    generateLink: (url, type) =>
      `mailto:?subject=I wanted you to see this ${type} from ${process.env.GATSBY_WEBSITE_TITLE}&body=Check out this ${process.env.GATSBY_WEBSITE_TITLE} ${type} ${url}`,
  },
  {
    icon: WhatsAppIcon,
    label: "WhatsApp",
    generateLink: (url, type) =>
      `https://wa.me/?text=Check out this ${type} from ${process.env.GATSBY_WEBSITE_TITLE} ${url}`,
  },
]

const Social = ({
  location,
  slug,
  title,
  shareQueryString = false,
  sharePath = false,
  gridIcons = false,
  pageType = "page",
}) => {
  const [copiedLink, setCopiedLink] = React.useState(false)
  const copyLinkRef = useRef()
  const { siteUrl } = useSiteMetaData()
  let sharingLink = `${siteUrl}/sleep-matters/${slug}`
  if (location.pathname) {
    let path = location.pathname
    if (shareQueryString) {
      path = `${path}${location.search}`
    }
    sharingLink = `${siteUrl}${path}`
  }

  // force rerender to ensure pagePros is fresh
  const [s, setS] = React.useState()
  React.useEffect(() => setS(1), [])

  const shareButtonLink = sharePath ? `${siteUrl}${sharePath}` : sharingLink

  const copyLinkToClipBoard = (event) => {
    event.preventDefault()
    copyLinkRef?.current?.select()
    document.execCommand?.("copy")
    alert("Share link has been copied")
  }

  return (
    <Container>
      <ShareButtonGroup className={gridIcons && "gridIcons"}>
        {buttons.map(({ icon, label, generateLink }, i) => (
          <ShareButton
            key={i}
            className={gridIcons && "gridIcon"}
            href={generateLink(encodeURIComponent(shareButtonLink), pageType)}
            target="_blank"
            rel="noreferrer noopener"
          >
            <SvgIcon SvgComponent={icon} hue="midnight" shade="040" />
            {!gridIcons && <span>{label}</span>}
          </ShareButton>
        ))}
        <ShareButton
          key="clipboard"
          className={gridIcons && "gridIcon"}
          target="_blank"
          rel="noreferrer noopener"
          onClick={copyLinkToClipBoard}
        >
          <SvgIcon SvgComponent={LinkIcon} hue="midnight" shade="040" />
          {!gridIcons && <span>{copiedLink ? "Copied!" : "Copy link"}</span>}
        </ShareButton>
      </ShareButtonGroup>
      <HiddenStyledTextArea
        ref={copyLinkRef}
        value={shareButtonLink}
        readOnly
      />
    </Container>
  )
}

const HiddenStyledTextArea = styled.textarea`
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  cursor: arrow;
`
const Container = styled.div``

const ShareButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  &.gridIcons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    flex-direction: column;
  }
`
const ShareButton = styled.a`
  border-radius: 3px;
  background-color: #cfd5db;
  border-radius: 3px;
  padding: 20px 10px;
  &.gridIcon {
    padding: 10px 10px;
  }
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  font-size: 16px;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  &:hover {
    transform: translateY(-3px);
  }
  .icon {
    margin-right: 5px;
  }
`

export default Social
