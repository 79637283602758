import React, { memo, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import BlogCard from "../BlogCard"
import Inner from "../../../layout/pageInner/Inner"

const RelatedPosts = memo(({ categories, currentSlug }) => {
  const [randomSelectedPosts, setRandomSelectedPosts] = useState([])

  const relatedPostsData = useStaticQuery(RELATED_POSTS_QUERY)

  const relatedCategories = categories.nodes
  const relatedPosts = relatedPostsData.allLocalWpGraphQlPosts.nodes

  const filteredRelatedPosts = []
  if (relatedPosts.length > 0) {
    relatedPosts.forEach((post) => {
      if (post.uri !== currentSlug) {
        post.categories.nodes.forEach((cat) => {
          relatedCategories.forEach((relCat) => {
            if (cat.name === relCat.name) {
              if (!filteredRelatedPosts.includes(post)) {
                filteredRelatedPosts.push(post)
              }
            }
          })
        })
      }
    })
  }

  if (randomSelectedPosts.length == 0) {
    const shuffled = filteredRelatedPosts.sort(() => 0.5 - Math.random())
    setRandomSelectedPosts(shuffled.slice(0, 4))
  }

  return (
    <RelatedPostsContainer>
      <Inner>
        <RelatedPostsTitle>
          <h2>Related Posts</h2>
        </RelatedPostsTitle>
        <RelatedPostsList>
          {randomSelectedPosts &&
            randomSelectedPosts.length > 0 &&
            randomSelectedPosts.map((post, i) => (
              <BlogCard key={i} post={post} />
            ))}
        </RelatedPostsList>
      </Inner>
    </RelatedPostsContainer>
  )
})

export default RelatedPosts

const RelatedPostsContainer = styled.div``

const RelatedPostsTitle = styled.div`
  display: block;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  h2 {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
  }
`

const RelatedPostsList = styled.div`
  //box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 2px;
  grid-gap: 20px;
  margin-bottom: 30px;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;
  }
`

const RELATED_POSTS_QUERY = graphql`
  {
    allLocalWpGraphQlPosts {
      nodes {
        postId
        title
        slug
        excerpt
        uri
        date
        featuredImage {
          sourceUrl
          mediaItemId
          imageFile {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        author {
          name
          slug
          avatar {
            url
          }
        }
        categories {
          nodes {
            name
          }
        }
        tags {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`
