import React from "react"

const SocialWhatsapp = ({ fill }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.585,2.25A18.574,18.574,0,0,0,2.94,20.752a18.31,18.31,0,0,0,2.676,9.553L2.25,40.231l10.323-3.279a18.677,18.677,0,0,0,27.658-16.2A18.574,18.574,0,0,0,21.585,2.25Zm9.272,25.528a4.816,4.816,0,0,1-3.3,2.126c-.874.046-.9.678-5.667-1.393s-7.635-7.108-7.861-7.433a9.144,9.144,0,0,1-1.759-4.956,5.283,5.283,0,0,1,1.815-3.877,1.826,1.826,0,0,1,1.291-.544c.375-.006.619-.011.9,0s.695-.058,1.056.9,1.224,3.319,1.335,3.56a.864.864,0,0,1,.009.828,3.238,3.238,0,0,1-.505.77c-.249.267-.523.6-.745.8-.248.226-.506.472-.246.955A14.268,14.268,0,0,0,19.7,22.886a13.009,13.009,0,0,0,3.739,2.485c.468.255.747.226,1.037-.079s1.241-1.335,1.577-1.795.649-.371,1.08-.2,2.726,1.4,3.194,1.658.779.384.891.585A3.915,3.915,0,0,1,30.856,27.778Z"
      fill={fill}
    />
  </svg>
)

export default SocialWhatsapp
